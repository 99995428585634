import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { useSession } from './hooks/useSession';
import DarkModeToggle from './components/DarkModeToggle';
import VirtualKeyboard from './components/VirtualKeyboard';
import HintModal from './components/HintModal';
import { GameBoard } from './components/GameBoard';
import { useGameLogic } from './hooks/useGameLogic';
import Metadata from './components/Metadata';

export default function App() {
  const { session, updateSettings, updateGameState } = useSession();

  const {
    getSolution,
    getDefinition,
    obfuscatedSolution,
    obfuscatedDefinition,
    guesses,
    currentGuess,
    gameOver,
    keyboardState,
    greenHints,
    wordId,
    handleKeyPress,
    handleHint,
    shareResults
  } = useGameLogic();

  const solution = getSolution();
  const definition = getDefinition();
  const [showHintModal, setShowHintModal] = useState(false);

  // We're now handling this in useGameLogic with debouncing
  // This useEffect has been removed to prevent duplicate state saving operations

  // Optimize the keydown handler to only update when handleKeyPress changes
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      handleKeyPress('enter');
    } else if (event.key === 'Backspace') {
      handleKeyPress('backspace');
    } else if (event.key.match(/^[a-z]$/i)) {
      handleKeyPress(event.key.toLowerCase());
    }
  }, [handleKeyPress]);

  // Add event listener with proper cleanup
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    // Cleanup function - important for preventing memory leaks
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleHintRequest = () => {
    setShowHintModal(true);
  };

  const handleHintConfirm = () => {
    setShowHintModal(false);
    handleHint();
  };

  // Check if player won the game
  const playerWon = gameOver && guesses.some(guess => guess === solution.replace(/ /g, ''));

  // Save theme preference when it changes
  const handleThemeChange = (theme) => {
    updateSettings({ theme });
  };

  return (
    <div className="App">
      <Metadata />
      <header>
        <h1>Slangle</h1>
        <div className="header-controls">
          <button className="hint-button" onClick={handleHintRequest}>Hint</button>
          <DarkModeToggle 
            currentTheme={session?.settings?.theme} 
            onChange={handleThemeChange}
          />
        </div>
        <HintModal
          isOpen={showHintModal}
          onClose={() => setShowHintModal(false)}
          onConfirm={handleHintConfirm}
        />
      </header>
      <GameBoard
        solution={solution}
        guesses={guesses}
        currentGuess={currentGuess}
        greenHints={greenHints}
      />
      {gameOver && (
        <div className="game-over">
          {playerWon ? (
            <p className="success-message">Congratulations! You won!</p>
          ) : (
            <p className="failure-message">Game Over! The word was "{solution}"</p>
          )}
          <button onClick={shareResults}>Share Results</button>
          <p className="definition">Definition: {definition}</p>
        </div>
      )}
      <VirtualKeyboard
        onKeyPress={handleKeyPress}
        keyboardState={keyboardState}
      />
    </div>
  );
}
