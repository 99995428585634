import React from 'react';
import { Helmet } from 'react-helmet';

export default function Metadata() {
  const title = "Slangle - Daily Slang Word Game";
  const description = "A Wordle like game using Urban Dictionary slang! Guess the slang word in 6 tries or less.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* You can add dynamic metadata here if needed */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      
      {/* Add structured data dynamically */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Slangle",
            "applicationCategory": "GameApplication",
            "description": "${description}",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "operatingSystem": "Any",
            "screenshot": "https://slangle.co/og-image.png",
            "url": "https://slangle.co/",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "ratingCount": "25",
              "reviewCount": "12",
              "bestRating": "5"
            }
          }
        `}
      </script>
    </Helmet>
  );
}