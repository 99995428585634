import { useState, useEffect, useCallback } from 'react';
import { isSupabaseConfigured } from '../supabaseClient';
import { useSession } from './useSession';
import { wordQueries } from '../queries';

const MAX_GUESSES = 6;

// Simple obfuscation functions for security
const obfuscate = (str) => {
    // Simple XOR cipher with a fixed key
    const key = 'slangle';
    let result = '';
    for (let i = 0; i < str.length; i++) {
        result += String.fromCharCode(str.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return btoa(result); // Add another layer with base64
};

const deobfuscate = (str) => {
    try {
        // Decode base64 first
        const decoded = atob(str);
        const key = 'slangle';
        let result = '';
        for (let i = 0; i < decoded.length; i++) {
            result += String.fromCharCode(decoded.charCodeAt(i) ^ key.charCodeAt(i % key.length));
        }
        return result;
    } catch (e) {
        // If the string isn't obfuscated or deobfuscation fails, return original
        return str;
    }
};

export function useGameLogic(initialSolution = '') {
    const { session, updateGameState: saveGameToSession } = useSession();

    // The obfuscated solution is stored in state
    const [obfuscatedSolution, setObfuscatedSolution] = useState(
        session?.gameState?.obfuscatedSolution || initialSolution
    );
    // Obfuscated definition stored in state
    const [obfuscatedDefinition, setObfuscatedDefinition] = useState(
        session?.gameState?.obfuscatedDefinition || ''
    );
    const [guesses, setGuesses] = useState(
        session?.gameState?.guesses || Array(MAX_GUESSES).fill('')
    );
    const [currentGuess, setCurrentGuess] = useState(
        session?.gameState?.currentGuess || ''
    );
    const [gameOver, setGameOver] = useState(
        session?.gameState?.gameOver || false
    );
    const [keyboardState, setKeyboardState] = useState(
        session?.gameState?.keyboardState || {}
    );
    const [hintedLetters, setHintedLetters] = useState(
        new Set(session?.gameState?.hintedLetters || [])
    );
    const [greenHints, setGreenHints] = useState(
        session?.gameState?.greenHints || {}
    );
    const [wordId, setWordId] = useState(
        session?.gameState?.wordId || null
    );

    // Get the actual solution when needed (computed property)
    const getSolution = useCallback(() => {
        return deobfuscate(obfuscatedSolution);
    }, [obfuscatedSolution]);

    // Get the actual definition when needed
    const getDefinition = useCallback(() => {
        return deobfuscate(obfuscatedDefinition);
    }, [obfuscatedDefinition]);

    const updateGameState = useCallback((newGuess) => {
        const solution = getSolution();
        const solutionArray = [...solution.replace(/ /g, '')];

        // Make sure newGuess is a string before spreading it
        const guessArray = typeof newGuess === 'string' ? [...newGuess] : [];

        const newKeyboardState = { ...keyboardState };
        const newGreenHints = { ...greenHints };

        // Greens
        guessArray.forEach((letter, index) => {
            if (letter === solutionArray[index]) {
                newKeyboardState[letter] = 'key-correct';
                newGreenHints[index] = letter;
            }
        });

        guessArray.forEach((letter, index) => {
            // If not green and still in solution, yellow
            if (letter !== solutionArray[index]) {
                if (solutionArray.includes(letter) && newKeyboardState[letter] !== 'key-correct') {
                    newKeyboardState[letter] = 'key-present';
                } else if (!solutionArray.includes(letter)) {
                    newKeyboardState[letter] = 'key-absent';
                }
            }
        });

        setKeyboardState(newKeyboardState);
        setGreenHints(newGreenHints);
    }, [getSolution, keyboardState, greenHints]);

    // Add effect to save game state to session with debounce
    useEffect(() => {
        // Only save if we have a solution
        if (!obfuscatedSolution) return;
        
        // Create a debounced save function to reduce excessive storage operations
        const debouncedSave = setTimeout(() => {
            const gameState = {
                obfuscatedSolution,
                obfuscatedDefinition,
                guesses,
                currentGuess,
                gameOver,
                keyboardState,
                hintedLetters: Array.from(hintedLetters),
                greenHints,
                wordId
            };
            saveGameToSession({gameState});
        }, 300); // 300ms debounce
        
        // Clean up the timeout on each render
        return () => clearTimeout(debouncedSave);
    }, [
        obfuscatedSolution,
        obfuscatedDefinition,
        guesses,
        currentGuess,
        gameOver,
        keyboardState,
        hintedLetters,
        greenHints,
        wordId,
        saveGameToSession
    ]);

    // Fetches the word of the day (main useEffect)
    useEffect(() => {
        // Flag to track if the component is mounted
        let isMounted = true;
        
        // Get word from Supabase with obfuscation
        const fetchWordOfDay = async () => {
            // Always fetch the current word of day first
            let currentWordData = null;
            let currentWordId = null;
            
            // First check if Supabase is properly configured
            if (isSupabaseConfigured()) {
                try {
                    console.log("Fetching current word");
                    // Attempt to get the active word from Supabase
                    currentWordData = await wordQueries.getWordOfDay();
                    currentWordId = currentWordData.id;
                    console.log("Successfully fetched current word");
                } catch (error) {
                    console.error('Error fetching word from Supabase:', error);
                }
            } else {
                console.warn("Supabase not configured, skipping Supabase fetch");
            }
            
            // Check if we have a saved game and if it's the same word
            const savedWordId = session?.gameState?.wordId;
            
            if (savedWordId && currentWordId && savedWordId === currentWordId) {
                // We have a saved game with the current word
                console.log("Resuming existing game with current word");
                return; // Use the state already initialized from session
            } else if (obfuscatedSolution && (!currentWordId || savedWordId !== currentWordId)) {
                // We have a saved game but it's for an older word, start fresh
                console.log("Found saved game but with outdated word. Starting fresh.");
                
                if (currentWordData) {
                    // Use the current word we've already fetched
                    if (isMounted) {
                        setObfuscatedSolution(obfuscate(currentWordData.word));
                        setObfuscatedDefinition(obfuscate(currentWordData.definition));
                        setWordId(currentWordData.id);
                        
                        // Reset game state for new word
                        setGuesses(Array(MAX_GUESSES).fill(''));
                        setCurrentGuess('');
                        setGameOver(false);
                        setKeyboardState({});
                        setHintedLetters(new Set());
                        setGreenHints({});
                    }
                    
                    // Record that this word has been used
                    if (currentWordData.id) {
                        try {
                            await wordQueries.updateWordUsage(currentWordData.id);
                        } catch (updateError) {
                            console.error('Error updating word usage:', updateError);
                        }
                    }
                    return;
                }
            } else if (!obfuscatedSolution && currentWordData) {
                // No saved game but we have the current word
                if (isMounted) {
                    setObfuscatedSolution(obfuscate(currentWordData.word));
                    setObfuscatedDefinition(obfuscate(currentWordData.definition));
                    setWordId(currentWordData.id);
                }
                
                // Record that this word has been used
                if (currentWordData.id) {
                    try {
                        await wordQueries.updateWordUsage(currentWordData.id);
                    } catch (updateError) {
                        console.error('Error updating word usage:', updateError);
                    }
                }
                return;
            }

            // Fallback to local JSON if Supabase fails or is not configured
            console.log('Falling back to local word list...');
            try {
                const response = await fetch('/urban_dictionary_words.json');
                if (!response.ok) {
                    throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                console.log(`Loaded ${data.length} words from local JSON`);
                const todayWord = data[data.length - 1];
                console.log("Using word:", todayWord.word);
                
                // Only update state if component is still mounted
                if (isMounted) {
                    setObfuscatedSolution(obfuscate(todayWord.word));
                    setObfuscatedDefinition(obfuscate(todayWord.definition));
                    
                    // Reset game state for new word if we had a saved game
                    if (obfuscatedSolution) {
                        setGuesses(Array(MAX_GUESSES).fill(''));
                        setCurrentGuess('');
                        setGameOver(false);
                        setKeyboardState({});
                        setHintedLetters(new Set());
                        setGreenHints({});
                    }
                }
            } catch (jsonError) {
                console.error('Error fetching local words:', jsonError);
                // Last resort fallback
                console.log("Using hardcoded fallback word");
                
                // Only update state if component is still mounted
                if (isMounted) {
                    setObfuscatedSolution(obfuscate('slangle'));
                    setObfuscatedDefinition(obfuscate('A word-guessing game based on Urban Dictionary slang words.'));
                    
                    // Reset game state for new word if we had a saved game
                    if (obfuscatedSolution) {
                        setGuesses(Array(MAX_GUESSES).fill(''));
                        setCurrentGuess('');
                        setGameOver(false);
                        setKeyboardState({});
                        setHintedLetters(new Set());
                        setGreenHints({});
                    }
                }
            }
        };

        fetchWordOfDay();
        
        // Cleanup function to prevent memory leaks
        return () => {
            isMounted = false;
        };
        // TODO: remove fix the lint
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Leave the empty dependency array to run this effect only once on mount

    const handleKeyPress = useCallback((key) => {
        if (gameOver) return;

        const solution = getSolution();

        if (key === 'enter') {
            if (currentGuess.length !== solution.replace(/ /g, '').length) return;

            setGuesses(prevGuesses => {
                const newGuesses = [...prevGuesses];
                const currentGuessIndex = newGuesses.findIndex(guess => guess === '');
                if (currentGuessIndex !== -1) {
                    newGuesses[currentGuessIndex] = currentGuess;
                    updateGameState(currentGuess);

                    if (currentGuess === solution.replace(/ /g, '')) {
                        setGameOver(true);
                    } else if (currentGuessIndex === MAX_GUESSES - 1) {
                        setGameOver(true);
                    }
                }
                return newGuesses;
            });

            setCurrentGuess('');
        } else if (key === 'backspace') {
            setCurrentGuess(prevGuess => prevGuess.slice(0, -1));
        } else if (currentGuess.length < solution.replace(/ /g, '').length) {
            setCurrentGuess(prevGuess => prevGuess + key);
        }
    }, [currentGuess, gameOver, getSolution, updateGameState]);

    const getNextHint = useCallback(() => {
        const solution = getSolution();
        const solutionArray = [...solution.replace(/ /g, '')];

        // First, try to find a yellow hint
        for (let i = 0; i < solutionArray.length; i++) {
            const letter = solutionArray[i];
            if (!greenHints[i] && !hintedLetters.has(letter) && (!keyboardState[letter] || keyboardState[letter] === 'key-absent')) {
                return { letter, type: 'yellow', position: i };
            }
        }

        // If all yellow hints are given, move to green hints
        for (let i = 0; i < solutionArray.length; i++) {
            if (!greenHints[i]) {
                return { letter: solutionArray[i], type: 'green', position: i };
            }
        }

        return null;
    }, [getSolution, hintedLetters, keyboardState, greenHints]);

    const handleHint = useCallback(() => {
        const hint = getNextHint();

        if (!hint) return; // Early return if no hint is available
        
        const solution = getSolution();
        const newKeyboardState = { ...keyboardState };
        const newHintedLetters = new Set(hintedLetters);
        const newGreenHints = { ...greenHints };

        if (hint.type === 'yellow') {
            newKeyboardState[hint.letter] = 'key-present';
            newHintedLetters.add(hint.letter);
        } else if (hint.type === 'green') {
            newKeyboardState[hint.letter] = 'key-correct';
            newGreenHints[hint.position] = hint.letter;
        }

        // Apply all consecutive green hints to the current guess
        // Create an array with the solution length, fill with spaces
        const solutionLength = solution.replace(/ /g, '').length;
        const newGuess = Array(solutionLength).fill(' ');
        
        // Fill in green hints
        for (let i = 0; i < solutionLength; i++) {
            if (newGreenHints[i]) {
                newGuess[i] = newGreenHints[i];
            } else if (i < currentGuess.length) {
                newGuess[i] = currentGuess[i];
            }
        }

        // Batch state updates to reduce renders
        setKeyboardState(newKeyboardState);
        setHintedLetters(newHintedLetters);
        setGreenHints(newGreenHints);
        setCurrentGuess(newGuess.join('').trim());
        
    }, [getNextHint, currentGuess, hintedLetters, keyboardState, getSolution, greenHints]);

    const generateShareText = useCallback(() => {
        const solution = getSolution();
        let shareText = `Slangle ${guesses.filter(guess => guess !== '').length}/${MAX_GUESSES}\n\n`;
        const solutionNoSpaces = solution.replace(/ /g, '');

        guesses.forEach((guess, index) => {
            if (guess !== '') {
                let solutionIndex = 0;
                guess.split('').forEach((letter, letterIndex) => {
                    while (solution[solutionIndex] === ' ') {
                        shareText += ' ';  // Add a space in the share text
                        solutionIndex++;
                    }
                    if (letter === solution[solutionIndex]) {
                        shareText += '🟩';
                    } else if (solutionNoSpaces.includes(letter)) {
                        shareText += '🟨';
                    } else {
                        shareText += '⬛';
                    }
                    solutionIndex++;
                });
                // Check if there are any trailing spaces in the solution
                while (solutionIndex < solution.length && solution[solutionIndex] === ' ') {
                    shareText += ' ';
                    solutionIndex++;
                }
                shareText += '\n';
            }
        });

        return shareText;
    }, [guesses, getSolution]);

    const shareResults = useCallback(() => {
        const shareText = generateShareText();

        if (navigator.share) {
            navigator.share({
                title: 'My Slangle Results',
                text: shareText,
            }).then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            // Fallback for browsers that don't support navigator.share
            // Using try/catch to handle potential clipboard API issues
            try {
                navigator.clipboard.writeText(shareText).then(() => {
                    alert('Results copied to clipboard!');
                }).catch(err => {
                    console.error('Failed to copy: ', err);
                    // Fallback if clipboard API fails
                    alert('Could not copy results automatically. Text: ' + shareText);
                });
            } catch (err) {
                console.error('Clipboard API not available:', err);
                alert('Could not copy results automatically. Text: ' + shareText);
            }
        }
    }, [generateShareText]);

    return {
        getSolution,
        getDefinition,
        obfuscatedSolution,
        obfuscatedDefinition,
        guesses,
        currentGuess,
        gameOver,
        keyboardState,
        greenHints,
        wordId, 
        handleKeyPress,
        handleHint,
        generateShareText,
        shareResults
    };
}