import { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';

export const wordQueries = {
  // Get the word of the day
  getWordOfDay: async () => {
    const { data, error } = await supabase
      .from('words')
      .select('*')
      .eq('active', true)
      .order('created_at', { ascending: false })
      .limit(1)
      .single();

    if (error) throw error;
    return data;
  },

  // Update word usage stats
  updateWordUsage: async (wordId) => {
    // First get the current times_used value
    const { data: word, error: fetchError } = await supabase
      .from('words')
      .select('times_used')
      .eq('id', wordId)
      .single();
    
    if (fetchError) throw fetchError;
    
    // Then update with the incremented value
    const { data, error } = await supabase
      .from('words')
      .update({ 
        times_used: (word.times_used || 0) + 1,
        last_used_at: new Date()
      })
      .eq('id', wordId)
      .select();

    if (error) throw error;
    return data;
  },

  // Get a specific word by ID
  getWordById: async (wordId) => {
    const { data, error } = await supabase
      .from('words')
      .select('*')
      .eq('id', wordId)
      .single()

    if (error) throw error
    return data
  },

  // Get a random word (can be used as fallback)
  getRandomWord: async () => {
    // Using SQL to get a random row is more efficient
    const { data, error } = await supabase
      .from('words')
      .select('*')
      .eq('active', true)
      .limit(1)
      .order('random()')
      .single()

    if (error) throw error
    return data
  }
}

// Custom hook for fetching word of the day
export const useWordOfDay = () => {
  const [word, setWord] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Flag to track if component is mounted
    let isMounted = true;
    
    const fetchWord = async () => {
      try {
        if (isMounted) setLoading(true)
        const data = await wordQueries.getWordOfDay()
        if (isMounted) setWord(data)
      } catch (err) {
        if (isMounted) setError(err.message)
      } finally {
        if (isMounted) setLoading(false)
      }
    }

    fetchWord()
    
    // Cleanup function to prevent memory leaks
    return () => {
      isMounted = false;
    }
  }, [])

  return { word, loading, error }
}