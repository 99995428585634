import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

// Add error handling for missing environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  console.error(
    "Error: Supabase URL or Anon Key is missing.",
    "URL:", supabaseUrl ? "✓" : "✗",
    "Key:", supabaseAnonKey ? "✓" : "✗"
  );
  console.error(
    "Please make sure you have created a .env file in the /slangle directory with:",
    "\nREACT_APP_SUPABASE_URL=https://your-project.supabase.co",
    "\nREACT_APP_SUPABASE_ANON_KEY=your-anon-key"
  );
}

// Provide a fallback for development/testing
const url = supabaseUrl || 'https://placeholder.supabase.co'
const key = supabaseAnonKey || 'placeholder'

export const supabase = createClient(url, key)

// Export a helper to check if Supabase is properly configured
export const isSupabaseConfigured = () => {
  return !!(supabaseUrl && supabaseAnonKey)
}