import React from 'react';

const HINT_TAUNTS = [
  "Taking the b*tch route, huh? Shocking.",
  "Hints are for pussies. Meow, motherf*cker.",
  "Damn, you're so dense light bends around you.",
  "Hints are for cowards. Bawk bawk, chicken sh*t.",
  "Your bloodline is weaker than watered-down piss.",
  "Your brain's smoother than a f*cking bowling ball.",
  "Christ, I've seen smarter things growing on cheese.",
  "Want a hint? Here's one: You suck. Mic drop, b*tch.",
  "Stuck already? Baa baa, little sheep. Time to get fleeced.",
  "Hints are for weaklings. Flex those two brain cells, meathead.",
  "Hint? How about I hint my foot up your *ss? Yippee ki-yay, loser.",
  "Asking for help? Why don't you ask your mom? Oh wait, she's busy...",
  "Want a hint? Here's one: Go play in traffic, you braindead f*cknugget.",
  "Stuck? Maybe if you pull your head out of your ass, you'll see the answer.",
  "Hint? Sure, right after you finish your crayon sandwich, you absolute muppet.",
  "Here's your f*cking hint, dipsh*t. Not that it'll help your pea-sized brain anyway.",
  "Hints are for losers. Go play in the shallow end of the gene pool, where you belong.",
  "Need help? Try rubbing your two brain cells together. Might spark a f*cking miracle.",
  "Want assistance? Try asking your imaginary friends. They're the only ones who can stand your dumb *ss."
];

const CANCEL_PHRASES = [
  "Nope, I'm out",
  "Retreat!",
  "I'd rather not",
  "Changed my mind",
  "Backing away slowly"
];

const HintModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  const randomTaunt = HINT_TAUNTS[Math.floor(Math.random() * HINT_TAUNTS.length)];

  const randomCancelPhrase = CANCEL_PHRASES[Math.floor(Math.random() * CANCEL_PHRASES.length)];

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Are you sure you want a hint?</h2>
        <p>{randomTaunt}</p>
        <div className="modal-buttons">
          <button className="modal-button cancel" onClick={onClose}>
            {randomCancelPhrase}
          </button>
          <button className="modal-button confirm" onClick={onConfirm}>
            Hint
          </button>
        </div>
      </div>
    </div>
  );
};

export default HintModal;