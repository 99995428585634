import React, { useState, useEffect } from 'react';
import { Moon, Sun } from 'lucide-react';

const DarkModeToggle = ({ initialTheme, onThemeChange }) => {
  const [theme, setTheme] = useState(initialTheme || 'system');

  const systemPrefersDark = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  };

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('dark-mode', 'light-mode');
    if (theme === 'dark' || (theme === 'system' && systemPrefersDark())) {
      root.classList.add('dark-mode');
    } else {
      root.classList.add('light-mode');
    }
    if (theme === 'system') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = () => {
        if (mediaQuery.matches) {
          root.classList.remove('light-mode');
          root.classList.add('dark-mode');
        } else {
          root.classList.remove('dark-mode');
          root.classList.add('light-mode');
        }
      };
      
      mediaQuery.addEventListener('change', handleChange);
      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = isDarkMode() ? 'light' : 'dark';
    setTheme(newTheme);
    if (onThemeChange) {
      onThemeChange(newTheme);
    }
  };

  const isDarkMode = () => {
    return theme === 'dark' || (theme === 'system' && systemPrefersDark());
  };

  return (
    <button 
      onClick={toggleTheme}
      className="dark-mode-toggle"
      aria-label={`Current theme: ${isDarkMode() ? 'dark' : 'light'}. Click to toggle.`}
    >
      {isDarkMode() ? <Sun size={24} /> : <Moon size={24} />}
    </button>
  );
};

DarkModeToggle.defaultProps = {
  initialTheme: 'system',
  onThemeChange: () => {}
};

export default DarkModeToggle;