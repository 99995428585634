import { useState, useEffect } from 'react';
import { loadSession, saveSession, defaultSession } from '../lib/sessionStorage';

export function useSession() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Initialize session on component mount
  useEffect(() => {
    // Flag to prevent state updates if component unmounts
    let isMounted = true;
    
    // Load session data
    const savedSession = loadSession() || defaultSession;
    // Update last visit timestamp
    savedSession.lastVisit = new Date().toISOString();
    saveSession(savedSession);
    
    // Only update state if component is still mounted
    if (isMounted) {
      setSession(savedSession);
      setIsLoading(false);
    }
    
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  // Function to update session
  const updateSession = (updates) => {
    setSession(prevSession => {
      const safeUpdates = { ...updates };

      if (safeUpdates.solution) {
        delete safeUpdates.solution;
      }
      if (safeUpdates.gameState && safeUpdates.gameState.solution) {
        delete safeUpdates.gameState.solution;
      }
      if (safeUpdates.definition) {
        delete safeUpdates.definition;
      }
      if (safeUpdates.gameState && safeUpdates.gameState.definition) {
        delete safeUpdates.gameState.definition;
      }

      const newSession = {
        ...prevSession,
        ...safeUpdates,
        lastVisit: new Date().toISOString()
      };
      saveSession(newSession);
      return newSession;
    });
  };

  // Function to update specific settings
  const updateSettings = (newSettings) => {
    updateSession({
      settings: {
        ...(session?.settings || {}),
        ...newSettings
      }
    });
  };

  // Function to update game state
  const updateGameState = (newGameState) => {
    const safeGameState = { ...newGameState };

    if (safeGameState.solution) {
      delete safeGameState.solution;
    }
    if (safeGameState.definition) {
      delete safeGameState.definition;
    }

    updateSession({
      gameState: {
        ...(session?.gameState || {}),
        ...safeGameState
      }
    });
  };

  return {
    session,
    isLoading,
    updateSession,
    updateSettings,
    updateGameState
  };
}
