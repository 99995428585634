import React from 'react';

function getBoxColor(guess, index, solution) {
    if (guess === '') return '';

    const solutionArray = [...solution];
    const guessArray = [...guess];
    
    const letterCounts = {};
    solutionArray.forEach(letter => {
        letterCounts[letter] = (letterCounts[letter] || 0) + 1;
    });

    // First pass: Mark green letters and decrease counts
    const colors = guessArray.map((letter, i) => {
        if (letter === solutionArray[i]) {
            letterCounts[letter]--;
            return 'green';
        }
        return null;
    });

    // Second pass: Mark yellow or grey letters
    guessArray.forEach((letter, i) => {
        if (colors[i] === null) {
            if (letterCounts[letter] > 0) {
                letterCounts[letter]--;
                colors[i] = 'yellow';
            } else {
                colors[i] = 'grey';
            }
        }
    });

    return colors[index];
}

export function GameBoard({ solution, guesses, currentGuess, greenHints }) {
    return (
        <section className="game-board" aria-label="Game Board">
            {guesses.map((guess, i) => (
                <div key={i} className="guess-row" role="row" aria-rowindex={i + 1}>
                    {solution.split('').map((char, j) => {
                        const adjustedIndex = j - solution.slice(0, j).split(' ').length + 1;
                        const isCurrentRow = guess === '' && i === guesses.findIndex(g => g === '');
                        const cellContent = guess !== '' 
                            ? guess[adjustedIndex] 
                            : (isCurrentRow ? currentGuess[adjustedIndex] : '');
                        
                        if (char === ' ') {
                            return <div key={j} className="guess-box space" role="separator" aria-hidden="true"></div>;
                        }
                        
                        const colorClass = getBoxColor(guess, adjustedIndex, solution.replace(/ /g, ''));
                        const isLocked = !!greenHints[adjustedIndex];
                        const isCurrent = isCurrentRow;
                        
                        let ariaLabel = '';
                        if (guess !== '') {
                            ariaLabel = `${cellContent || 'Empty'}, ${colorClass === 'green' ? 'correct' : (colorClass === 'yellow' ? 'present' : 'absent')}`;
                        } else if (isCurrentRow) {
                            ariaLabel = `Current guess: ${cellContent || 'Empty'}`;
                        }
                        
                        return (
                            <div
                                key={j}
                                role="cell"
                                aria-colindex={j + 1}
                                aria-label={ariaLabel}
                                className={`guess-box ${colorClass} ${isCurrent ? 'current' : ''} ${isLocked ? 'locked' : ''}`}
                                data-state={colorClass || 'empty'}
                            >
                                {cellContent}
                            </div>
                        );
                    })}
                </div>
            ))}
        </section>
    );
}