// Session storage utilities for Slangle

const SESSION_KEY = 'slangle_session';

// Save session data to localStorage
export function saveSession(sessionData) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(SESSION_KEY, JSON.stringify(sessionData));
  }
}

// Load session data from localStorage
export function loadSession() {
  if (typeof window !== 'undefined') {
    const data = localStorage.getItem(SESSION_KEY);
    return data ? JSON.parse(data) : null;
  }
  return null;
}

// Clear session data
export function clearSession() {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(SESSION_KEY);
  }
}

// Default session structure
export const defaultSession = {
  settings: {
    theme: 'system',
  },
  gameState: {
    // Game progress, hints, etc.
  },
  lastVisit: null,
};
